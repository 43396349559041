import React from 'react';
import {Link} from 'react-router-dom';

const AboutVideo = () => {
  return (
    <>
        <div className="video-area video-area__2">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-xl-12">
                        <div className="video__bg video__bg--2 bg_img" data-background="assets/images/bg/video-bg-1.jpeg"
                            data-overlay="dark" data-opacity="5">
                            <div className="video-container-wrap video-container-wrap__2">
                                <Link to="//www.youtube.com/embed/4xe72U7mXNg?rel=0&amp;controls=0&amp;showinfo=0"
                                    data-rel="lightcase:myCollection" data-animation="fadeInLeft" data-delay=".1s"
                                    className="video-link video-link__2">
                                    <div className="video-play-wrap video-play-wrap__2">
                                        <div className="video-mark video-mark__2">
                                            <div className="wave-pulse wave-pulse-1"></div>
                                            <div className="wave-pulse wave-pulse-2"></div>
                                        </div>
                                        <div className="video-play video-play__2">
                                            <i className="fa fa-play"></i>
                                        </div>
                                    </div>
                                </Link>
                                <Link className="video-text video-text__2"
                                    to="//www.youtube.com/embed/4xe72U7mXNg?rel=0&amp;controls=0&amp;showinfo=0"
                                    data-rel="lightcase:myCollection" data-animation="fadeInLeft" data-delay=".1s">Play
                                    Intro Video</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AboutVideo
