import React from 'react'
import ProductBreadcrumb from '../components/OurProducts/ProductBreadcrumb';
import ProductDetail from '../components/OurProducts/ProductDetail';
import ProductShowcase from '../components/OurProducts/ProductShowcase';
import BrandSection from '../components/BrandSection';

const OurProducts = () => {
  return (
    <>
      <ProductBreadcrumb />
      <ProductDetail />
      <ProductShowcase />
      <BrandSection />
    </>
  )
}

export default OurProducts
