import React from 'react'

const CompanyFeature = () => {
  return (
    <>
        <section className="feature-area pt-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8 text-center">
                        <div className="section-header mb-50">
                            <h4 className="sub-heading mb-25">
                                <span><img src="assets/images/shape/heading-shape-1.png" className="mr-5" alt="" /></span>
                                Company Feature
                                <span><img src="assets/images/shape/heading-shape-2.png" className="ml-5" alt="" /></span>
                            </h4>
                            <h2 className="section-title">Digital Printing</h2>
                        </div>
                    </div>
                </div>
                <div className="row mt-none-30">
                    <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                        <div className="feature-item">
                            <div className="feature-item__icon feature-item__icon--1">
                                <img src="assets/images/icons/f-1.png" alt="" />
                            </div>
                            <div className="feature-item__content">
                                <h4 className="feature-item__title">Digital Printing</h4>
                                <p>Welcome to our digital printing website, where imagination comes to life! We specialize in transforming your ideas into vibrant reality through high-quality digital printing solutions. Explore our services and let us bring your visions to print today!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                        <div className="feature-item">
                            <div className="feature-item__icon feature-item__icon--2">
                                <img src="assets/images/icons/f-2.png" alt="" />
                            </div>
                            <div className="feature-item__content">
                                <h4 className="feature-item__title">Brand Printing</h4>
                                <p>Unleash the power of your brand with our exceptional printing services. We are your trusted partner in delivering impactful and memorable print materials that reflect your brand's identity.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                        <div className="feature-item">
                            <div className="feature-item__icon feature-item__icon--3">
                                <img src="assets/images/icons/f-3.png" alt="" />
                            </div>
                            <div className="feature-item__content">
                                <h4 className="feature-item__title">3d Printing</h4>
                                <p>Welcome to our  3D printing hub, where innovation takes shape! our advanced technology and expertise ensure the highest quality and fastest turnaround time. Start exploring the world of 3D printing and unlock a new dimension of design with us.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                        <div className="feature-item">
                            <div className="feature-item__icon feature-item__icon--4">
                                <img src="assets/images/icons/f-4.png" alt="" />
                            </div>
                            <div className="feature-item__content">
                                <h4 className="feature-item__title">Offset Printing</h4>
                                <p>Welcome to our premier offset printing services, where quality meets perfection. Whether it's brochures, catalogs, or business cards, trust us to deliver exceptional results that make your brand stand out.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default CompanyFeature
