import React from 'react'
import { Link } from 'react-router-dom';

const ContactInfo = () => {
  return (
    <>
        <div className="contact-info-area pt-130">
            <div className="container">
                <div className="row mt-none-30">
                    <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                        <div className="contact-info">
                            <h4 className="contact-info__title">California</h4>
                            <p>Briercliff Road Queens</p>
                            <Link to="mailto:info@basictheme.com">info@basictheme.com</Link>
                            <Link to="tel:2025886500">(202) 588-6500</Link>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                        <div className="contact-info">
                            <h4 className="contact-info__title">Georgia</h4>
                            <p>914 Ward Road Paso</p>
                            <Link to="mailto:info@basictheme.com">info@basictheme.com</Link>
                            <Link to="tel:2025886500">(202) 588-6500</Link>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                        <div className="contact-info">
                            <h4 className="contact-info__title">Maryland</h4>
                            <p>Goldcliff Circle Buffelo</p>
                            <Link to="mailto:info@basictheme.com">info@basictheme.com</Link>
                            <Link to="tel:2025886500">(202) 588-6500</Link>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                        <div className="contact-info">
                            <h4 className="contact-info__title">New York</h4>
                            <p>Kidd Avenue Palmos</p>
                            <Link to="mailto:info@basictheme.com">info@basictheme.com</Link>
                            <Link to="tel:2025886500">(202) 588-6500</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ContactInfo
