import React from 'react';
import { Link } from 'react-router-dom';

const ProductShowcase = () => {
  return (
    <>
        <div className="project-area project-area__2 pt-125">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8 text-center">
                        <div className="section-header mb-65">
                            <h4 className="sub-heading sub-heading__2 mb-15">
                                <span><img src="assets/images/shape/heading-shape-3.png" className="mr-5" alt="" /></span>
                                Product showcase
                                <span><img src="assets/images/shape/heading-shape-4.png" className="ml-5" alt="" /></span>
                            </h4>
                            <h2 className="section-title section-title__2">Our Products</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row project-row mt-none-30">
                    <div className="col-xl-5 col-lg-6 col-md-6 d-flex align-self-stretch mt-30">
                        <div className="project-item">
                            <div className="project-item__thumb project-item__thumb--big">
                                <img src="assets/images/project/p-10.jpeg" alt="" />
                            </div>
                            <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                <Link to="project-details.html" className="project-item__link"><i
                                        className="far fa-arrow-right"></i></Link>
                                <div className="project-item__content">
                                    <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                    <h4 className="project-item__title">Megazine Cover</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 mt-30">
                        <div className="row mt-none-30">
                            <div className="col-xl-6 col-md-6 col-lg-6 mt-30">
                                <div className="project-item">
                                    <div className="project-item__thumb">
                                        <img src="assets/images/project/p-7.jpeg" alt="" />
                                    </div>
                                    <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                        <Link to="project-details.html" className="project-item__link"><i
                                                className="far fa-arrow-right"></i></Link>
                                        <div className="project-item__content">
                                            <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                            <h4 className="project-item__title">Megazine Cover</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6 col-lg-6 mt-30">
                                <div className="project-item">
                                    <div className="project-item__thumb">
                                        <img src="assets/images/project/p-11.jpeg" alt="" />
                                    </div>
                                    <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                        <Link to="project-details.html" className="project-item__link"><i
                                                className="far fa-arrow-right"></i></Link>
                                        <div className="project-item__content">
                                            <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                            <h4 className="project-item__title">Megazine Cover</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-6 col-lg-12 col-md-12 mt-30">
                                <div className="project-item">
                                    <div className="project-item__thumb">
                                        <img src="assets/images/project/p-12.jpeg" alt="" />
                                    </div>
                                    <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                        <Link to="project-details.html" className="project-item__link"><i
                                                className="far fa-arrow-right"></i></Link>
                                        <div className="project-item__content">
                                            <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                            <h4 className="project-item__title">Megazine Cover</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 d-flex mt-30">
                        <div className="project-item">
                            <div className="project-item__thumb project-item__thumb--big">
                                <img src="assets/images/project/p-5.jpeg" alt="" />
                            </div>
                            <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                <Link to="project-details.html" className="project-item__link"><i
                                        className="far fa-arrow-right"></i></Link>
                                <div className="project-item__content">
                                    <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                    <h4 className="project-item__title">Megazine Cover</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 d-flex mt-30">
                        <div className="project-item">
                            <div className="project-item__thumb project-item__thumb--big">
                                <img src="assets/images/project/p-8.jpeg" alt="" />
                            </div>
                            <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                <Link to="project-details.html" className="project-item__link"><i
                                        className="far fa-arrow-right"></i></Link>
                                <div className="project-item__content">
                                    <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                    <h4 className="project-item__title">Megazine Cover</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 d-flex mt-30">
                        <div className="project-item">
                            <div className="project-item__thumb project-item__thumb--big">
                                <img src="assets/images/project/p-6.jpeg" alt="" />
                            </div>
                            <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                <Link to="project-details.html" className="project-item__link"><i
                                        className="far fa-arrow-right"></i></Link>
                                <div className="project-item__content">
                                    <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                    <h4 className="project-item__title">Megazine Cover</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ProductShowcase
