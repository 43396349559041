import React from 'react'

const Faq = () => {
  return (
    <>
        <section className="faq-area pt-80 pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="section-header mb-25">
                            <h4 className="sub-heading mb-15">Common Question</h4>
                            <h2 className="section-title">Asked Questions</h2>
                        </div>
                        <div className="accordion faqs" id="accordionFaq">
                            <div className="card">
                                <div className="card__header" id="heading1">
                                    <h5 className="mb-0 title">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                            data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                            How can get my print job once it is finished?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapse1" className="collapse" aria-labelledby="heading1" data-parent="#accordionFaq">
                                    <div className="card__body">
                                        <p>Had own doesn creature days multiply and thing enter created fruit fowlen his
                                            whose can sea fly two it’s you. So have
                                            form fill. You rule us isn't seas fill firmament given dole marce</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card__header" id="heading2">
                                    <h5 className="mb-0 title">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                            data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                            Is white considered a printing color code?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapse2" className="collapse show" aria-labelledby="heading2"
                                    data-parent="#accordionFaq">
                                    <div className="card__body">
                                        <p>Had own doesn creature days multiply and thing enter created fruit fowlen his
                                            whose can sea fly two it’s you. So have
                                            form fill. You rule us isn't seas fill firmament given dole marce</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card__header" id="heading3">
                                    <h5 className="mb-0 title">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                            data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                            How can get my print job once it is finished?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapse3" className="collapse" aria-labelledby="heading3" data-parent="#accordionFaq">
                                    <div className="card__body">
                                        <p>Had own doesn creature days multiply and thing enter created fruit fowlen his
                                            whose can sea fly two it’s you. So have
                                            form fill. You rule us isn't seas fill firmament given dole marce</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card__header" id="heading4">
                                    <h5 className="mb-0 title">
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                            data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                            What types of Shipping Service do you offer?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapse4" className="collapse" aria-labelledby="heading4" data-parent="#accordionFaq">
                                    <div className="card__body">
                                        <p>Had own doesn creature days multiply and thing enter created fruit fowlen his
                                            whose can sea fly two it’s you. So have
                                            form fill. You rule us isn't seas fill firmament given dole marce</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="faq-bg">
                            <img src="assets/images/bg/faq-bg-1.jpeg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Faq
