import React from 'react';
import {Routes, Route} from 'react-router-dom';
import { useEffect } from 'react';
// import Preloader from './components/Preloader';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import OurProducts from './pages/OurProducts';
import Contact from './pages/Contact';
import Locations from './pages/Locations';

function App() {
  
  useEffect(() => {
    document.title = 'Best Offset Printing';
  }, []);

  return (
    <>
        {/* <Preloader /> */}
        <Header />

            <Routes>
                <Route path='/' element = { <Home /> }></Route>
                <Route path='/about' element = { <About /> }></Route>
                <Route path='/our-products' element = { <OurProducts /> }></Route>
                <Route path='/contact' element = { <Contact /> }></Route>
                <Route path='/locations' element = { <Locations /> }></Route>
            </Routes>

        <Footer />
    </>
  );
}

export default App;
