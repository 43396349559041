import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

//Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const BrandSection = () => {

    const brandImg = [
        {
            default: "/assets/images/brand/brand-1.png",
            hover: "/assets/images/brand/brand-1-h.png"
        },
        {
            default: "/assets/images/brand/brand-2.png",
            hover: "/assets/images/brand/brand-2-h.png"
        },
        {
            default: "/assets/images/brand/brand-3.png",
            hover: "/assets/images/brand/brand-3-h.png"
        },
        {
            default: "/assets/images/brand/brand-4.png",
            hover: "/assets/images/brand/brand-4-h.png"
        },
        {
            default: "/assets/images/brand/brand-5.png",
            hover: "/assets/images/brand/brand-5-h.png"
        },
         {
            default: "/assets/images/brand/brand-1.png",
            hover: "/assets/images/brand/brand-1-h.png"
        }
    ];

  return (
    <>
        <div className="brand-section pt-125 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="brand-carousel">
                            <Swiper
                                modules={[Navigation]}
                                spaceBetween={2}
                                slidesPerView={3}
                                autoplaydisableoninteraction={"false"}
                                loop={true}
                                
                                breakpoints={{

                                    // when window width is >= 768px
                                    576: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        // when window width is >= 992px
                                        slidesPerView: 3,
                                    },
                                    992: {
                                        // when window width is >= 992px
                                        slidesPerView: 4,
                                    },
                                    1024: {
                                        // when window width is >= 992px
                                        slidesPerView: 5,
                                    }
                                }}
                            >
                                {
                                    brandImg.map((item, i) =>
                                        <SwiperSlide key={i}>
                                            <div className="brand-carousel__item">
                                                <img className="default" src={item.default} alt="" />
                                                <img className="hover" src={item.hover} alt="" />
                                            </div>
                                        </SwiperSlide>
                                        
                                    )
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default BrandSection
