import React from 'react';

import HomeSlider from '../components/HomeSlider';
import CompanyFeature from '../components/CompanyFeature';
import About from '../components/About';
import ProjectArea from '../components/ProjectArea';
import ServiceArea from '../components/ServiceArea';
import Testimonials from '../components/Testimonials';
import Pricing from '../components/Pricing';
import NewsArea from '../components/NewsArea';
import CtaArea from '../components/CtaArea';
import GtaArea from '../components/GtaArea';
import Faq from '../components/Faq';
import BrandSection from '../components/BrandSection';


const Home = () => {
  return (
    <>
        
        <HomeSlider />
        <CompanyFeature />
        <About />
        <ProjectArea />
        <ServiceArea />
        <Testimonials />
        <Pricing />
        <NewsArea />
        <CtaArea />
        <GtaArea />
        <Faq />
        <BrandSection />
    </>
  )
}

export default Home
