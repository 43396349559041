import React from 'react'

const Team = () => {
  return (
    <>
        <div className="team-section team-section__2 pt-125 pb-125">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8 text-center">
                        <div className="section-header mb-65">
                            <h4 className="sub-heading sub-heading__2 sub-heading__red  mb-15">
                                <span><img src="assets/images/shape/heading-shape-3.png" className="mr-5" alt="" /></span>
                                team member
                                <span><img src="assets/images/shape/heading-shape-4.png" className="ml-5" alt="" /></span>
                            </h4>
                            <h2 className="section-title section-title__2">Executive Team</h2>
                        </div>
                    </div>
                </div>
                <div className="row mt-none-40">
                    <div className="col-xl-4 col-lg-6 col-md-6 mt-40">
                        <div className="team-box team-box__2">
                            <div className="team-box__thumb">
                                <img src="assets/images/team/team-1.jpeg" alt="" />
                            </div>
                            <div className="team-box__content team-box__content--2">
                                <h4 className="name">Raymond Archer</h4>
                                <span className="designation">Senior Desinger</span>
                                <div className="social-links social-links__2">
                                    <a href="#0"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#0"><i className="fab fa-twitter"></i></a>
                                    <a href="#0"><i className="fab fa-pinterest-p"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mt-40">
                        <div className="team-box team-box__2">
                            <div className="team-box__thumb">
                                <img src="assets/images/team/team-2.jpeg" alt="" />
                            </div>
                            <div className="team-box__content team-box__content--2">
                                <h4 className="name">Rituana Lohan</h4>
                                <span className="designation">Project Manager</span>
                                <div className="social-links social-links__2">
                                    <a href="#0"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#0"><i className="fab fa-twitter"></i></a>
                                    <a href="#0"><i className="fab fa-pinterest-p"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mt-40">
                        <div className="team-box team-box__2">
                            <div className="team-box__thumb">
                                <img src="assets/images/team/team-3.jpeg" alt="" />
                            </div>
                            <div className="team-box__content team-box__content--2">
                                <h4 className="name">Raymond Archer</h4>
                                <span className="designation">Senior Desinger</span>
                                <div className="social-links social-links__2">
                                    <a href="#0"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#0"><i className="fab fa-twitter"></i></a>
                                    <a href="#0"><i className="fab fa-pinterest-p"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Team
