import React from 'react';
// import { Link } from 'react-router-dom';

const ProductDetail = () => {
  return (
    <>
        <div className="details-content-area pt-125 pb-125">
            <div className="container">
                <div className="row mt-40">
                    <div className="col-xl-12">
                        <div className="details-content details-content__project">
                            <h2 className="details-content__title mb-20">Project Description</h2>
                            <p>Maecenas nise vestibulum parturient habitasse. Aliquam pellentesque fermentum. Sodales luctus
                                venenatis dis curae
                                senectus montes tortor morbi cubilia congue. Mus imperdiet turpis in augue placerat maecenas
                                natoque sollicitudin at
                                quam adipiscing integer dis ads se purus sollicitudin dapibus et vivamus pharetra sit
                                integer dictum in dise natoque a
                                mus quis in. Facilisis inceptos nec, potenti nostra aenean lacinia varius semper ant nullam
                                nulla primis placerat
                                facilisis. Netus lorem rutrum arcu dignissim at sit morbi phasellus nascetur eget urna
                                potenti cum vestibulum cras.
                                Tempor nonummy metus lobortis. Sociis velit etiam, dapibus. Lectus vehicula pellentesque
                                cras posuere tempor facilisi
                                habitant lectus rutrum pede quisque hendrerit parturient posuere mauris ad elementum
                                fringilla facilisi volutpat fusce
                                pharetra felis sapien varius quisque class convallis praesent est sollicitudin donec nulla
                                venenatis, cursus fermentum
                                netus posuere sociis porta risus habitant malesuada nulla habitasse hymenaeos. Viverra
                                curabitur nisi vel sollicitudin
                                dictum natoque ante aenean elementum curae malesuada ullamcorper. vivamus nonummy nisl
                                posuere rutrum</p>
                            <div className="content mt-20">
                                <p>Duis porttitor sem sit ullamcorper massa lorem platea ultricies sollicitudin. Habitant
                                    cubilia varius platea nam lorem
                                    cum aenean sociosqu and per bibendum Arcu accumsan eleifend. Ipsum nam condimentum
                                    lobortis eu morbi a quis ipsum est
                                    bibendum mine vulputate hymenaeos taciti odio nullam ligula ultrices tristique viverra
                                    laoreet sapien curae;.
                                    Sollicitudin etiam elit torquent. Purus nascetur dis augue non iaculis ullamcorper lacus
                                    met quam commodo hendrerit
                                    lobortis vel. Sagittis felis iaculis mauris luctus</p>
                                {/* <h2 className="details-content__title mt-30 mb-35">Working Process</h2> */}
                            </div>
                            {/* <div className="row mt-none-30">
                                <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                                    <div className="wp-box">
                                        <div className="wp-box__icon wp-box__icon--1 mb-25">
                                            <img src="assets/images/icons/w-p-1.png" alt="" />
                                        </div>
                                        <div className="wp-box__content">
                                            <h4 className="wp-box__title wp-box__title--2">Booking Online</h4>
                                            <p>God They moving an firmament seed over herb gathering multis ply morning
                                                fruitful</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                                    <div className="wp-box">
                                        <div className="wp-box__icon wp-box__icon--2 mb-25">
                                            <img src="assets/images/icons/w-p-2.png" alt="" />
                                        </div>
                                        <div className="wp-box__content">
                                            <h4 className="wp-box__title wp-box__title--2">Received Work</h4>
                                            <p>God They moving an firmament seed over herb gathering multis ply morning
                                                fruitful</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                                    <div className="wp-box">
                                        <div className="wp-box__icon wp-box__icon--3 mb-25">
                                            <img src="assets/images/icons/w-p-3.png" alt="" />
                                        </div>
                                        <div className="wp-box__content">
                                            <h4 className="wp-box__title wp-box__title--2">Satisfied Design</h4>
                                            <p>God They moving an firmament seed over herb gathering multis ply morning
                                                fruitful</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 mt-30">
                                    <div className="wp-box">
                                        <div className="wp-box__icon wp-box__icon--4 mb-25">
                                            <img src="assets/images/icons/w-p-4.png" alt="" />
                                        </div>
                                        <div className="wp-box__content">
                                            <h4 className="wp-box__title wp-box__title--2">Start Printing</h4>
                                            <p>God They moving an firmament seed over herb gathering multis ply morning
                                                fruitful</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="row mt-10">
                                <div className="col-xl-6 col-lg-6 col-md-6 d-flex mt-30">
                                    <div className="project-item">
                                        <div className="project-item__thumb project-item__thumb--big">
                                            <img src="assets/images/project/p-13.jpeg" alt="" />
                                        </div>
                                        <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                            <Link to="project-details.html" className="project-item__link">
                                                <i className="far fa-arrow-right"></i>
                                            </Link>
                                            <div className="project-item__content">
                                                <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                                <h4 className="project-item__title">Megazine Cover</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 d-flex mt-30">
                                    <div className="project-item">
                                        <div className="project-item__thumb project-item__thumb--big">
                                            <img src="assets/images/project/p-14.jpeg" alt="" />
                                        </div>
                                        <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                            <Link to="project-details.html" className="project-item__link">
                                                <i className="far fa-arrow-right"></i>
                                            </Link>
                                            <div className="project-item__content">
                                                <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                                <h4 className="project-item__title">Megazine Cover</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 d-flex mt-30">
                                    <div className="project-item">
                                        <div className="project-item__thumb project-item__thumb--big">
                                            <img src="assets/images/project/p-15.jpeg" alt="" />
                                        </div>
                                        <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                            <Link to="project-details.html" className="project-item__link">
                                                <i className="far fa-arrow-right"></i>
                                            </Link>
                                            <div className="project-item__content">
                                                <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                                <h4 className="project-item__title">Megazine Cover</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 d-flex mt-30">
                                    <div className="project-item">
                                        <div className="project-item__thumb project-item__thumb--big">
                                            <img src="assets/images/project/p-16.jpeg" alt="" />
                                        </div>
                                        <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                            <Link to="project-details.html" className="project-item__link">
                                                <i className="far fa-arrow-right"></i>
                                            </Link>
                                            <div className="project-item__content">
                                                <h5 className="project-item__subtitle"><span> </span> Book Print</h5>
                                                <h4 className="project-item__title">Megazine Cover</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ProductDetail
