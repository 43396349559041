import React from "react";
import {Link} from 'react-router-dom';

function Footer (){
    return (
        <>
            <footer className="footer pt-120">
                <div className="container">
                    <div className="row mt-none-50 justify-content-center">
                        <div className="col-xl-2 col-lg-3 mt-50">
                            <a href="index.html" className="footer__logo">
                                <img src="assets/images/logo/logo-white.png" alt="" />
                            </a>
                        </div>
                        <div className="col-xl-2 col-lg-4 mt-50 pl-45 pr-0">
                            <div className="footer-widget">
                                <h4 className="widget-title">Our Service</h4>
                                <ul>
                                    <li><Link to="service.html"><i className="fa fa-angle-right"></i> Business Card</Link></li>
                                    <li><Link to="service.html"><i className="fa fa-angle-right"></i> Flyer Printing</Link></li>
                                    <li><Link to="service.html"><i className="fa fa-angle-right"></i> Banner Printing</Link></li>
                                    <li><Link to="service.html"><i className="fa fa-angle-right"></i> Mug Printing</Link></li>
                                    <li><Link to="service.html"><i className="fa fa-angle-right"></i> Sticker Printing</Link></li>
                                    <li><Link to="/locations"><i className="fa fa-angle-right"></i> Locations</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-5 mt-50 pl-70 pr-0">
                            <div className="footer-widget">
                                <h4 className="widget-title">Recent News</h4>
                                <div className="recent-news mt-none-20">
                                    <div className="recent-news__content mt-20">
                                        <Link to="news-details.html" className="recent-news__title">Rutrum felis facilisi lorem com modo habitasse
                                            portam</Link>
                                        <Link to="news-details.html" className="recent-news__date">Sep 20. 2020</Link>
                                    </div>
                                    <div className="recent-news__content mt-20">
                                        <Link to="news-details.html" className="recent-news__title">Montes conubia alique vel nisl cras maecenas
                                            enim hac.</Link>
                                        <Link to="news-details.html" className="recent-news__date">Sep 21. 2020</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 offset-xl-1 col-lg-6 mt-50">
                            <div className="footer-widget">
                                <div className="newslater">
                                    <h4 className="newslater__title">Subscribe to our
                                        Newsletter</h4>
                                    <div className="newslater__form">
                                        <form action="#">
                                            <input type="email" name="email" id="email" placeholder="Enter Email" />
                                            <button type="submit"><i className="far fa-paper-plane"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom mt-115">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 my-auto">
                                <div className="copyright-text">
                                    <p>Copyright &copy; <Link to="https://creativizt.com">Creativizt Communications.</Link> All Rights Reserved.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="social__links">
                                    <Link to="https://facebook.com/"><i className="fab fa-facebook-f"></i></Link>
                                    <Link to="https://twitter.com/"><i className="fab fa-twitter"></i></Link>
                                    <Link to="https://pinterest.com/"><i className="fab fa-pinterest-p"></i></Link>
                                    <Link to="https://linkedin.com"><i className="fab fa-linkedin-in"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;