import React from 'react'
import CompanyFeature from '../components/CompanyFeature';
import About from '../components/About';
const Locations = () => {
  return (
    <>
        <CompanyFeature />
        <About />
    </>
  )
}

export default Locations
