import React from 'react'

const Breadcrumb = () => {
  return (
    <>
        <section class="breadcrumb-section pt-180 pb-180 bg_img" style={{"backgroundImage":"url(/assets/images/bg/breadcrumb-bg-1.jpeg)"}} data-overlay="dark" data-opacity="3">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 d-flex">
                        <div class="breadcrumb-text">
                            <h2 class="breadcrumb-text__title">
                                About us
                            </h2>
                            <ul class="breadcrumb-text__nav">
                                <li><a href="index.html">Home</a></li>
                                <li>-</li>
                                <li>about us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Breadcrumb
