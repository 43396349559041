import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

//Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const Testimonial = () => {

    const testimonial_data = [
        {
            desc: "Testimonial 1 Lorem ipsum dolor site amet consectetur adipisice                                        usmods tempor incididunt uter labore etere dolore magna aliqua enim ad minim veniam quis    nostrud exercitation ule lamco lris nisi ut aliquip ex ea commodo consequ rure dolor reprehende in voluptate",
            author_img: "assets/images/other/author-2.png",
            author_name: "Marina D. Lucator",
            author_designation: "Project Manager"
        },
        {
            desc: "Testimonial 2 Lorem ipsum dolor site amet consectetur adipisice usmods tempor incididunt uter labore etere dolore magna aliqua enim ad minim veniam quis nostrud exercitation ule lamco lris nisi ut aliquip ex ea commodo consequ rure dolor reprehende in voluptate",
            author_img: "assets/images/other/author-2.png",
            author_name: "Marina D. Lucator",
            author_designation: "Project Manager"
        }
    ]

  return (
    <>
        <div className="testimonial-area testimonial-area__2 testimonial-area__3 pt-130 pb-130">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="testimonial__3 text-center position-relative">
                            <Swiper
                                    modules={[Navigation]}
                                    spaceBetween={20}
                                    slidesPerView={1}
                                    autoplaydisableoninteraction={"false"}
                                    loop={true}
                                    className="custom-class"
                                    navigation={{
                                        prevEl: ".custom-prev",
                                        nextEl: ".custom-next",
                                    }}
                                >
                                    {
                                        testimonial_data.map( (item, i) => 
                                            <SwiperSlide key={i}>
                                                <div className="testimonial-item">
                                                    <div className="testimonial__2--icon mb-70">
                                                        <img src="assets/images/icons/t-quote-2.png" alt="" />
                                                    </div>
                                                    <div className="testimonial__2--content testimonial__2--content--2">
                                                        <p>{item.desc}</p>
                                                    </div>
                                                    <div className="testimonial__2--author mt-55">
                                                        <div className="thumb">
                                                            <img src={item.author_img} alt="" />
                                                        </div>
                                                        <div className="content content__2">
                                                            <h4 className="name">{item.author_name}</h4>
                                                            <span className="designation">{item.author_designation}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                </Swiper>
                                <div className="owl-nav">
                                    <div className="owl-prev custom-prev"><i className="fal fa-long-arrow-left"></i></div>
                                    <div className="owl-next custom-next"><i className="fal fa-long-arrow-right"></i></div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Testimonial
