import React from 'react';
import Breadcrumb from '../components/About/Breadcrumb';
import AboutSection from '../components/About/AboutSection';
import AboutVideo from '../components/About/AboutVideo';
import AboutCounter from '../components/About/AboutCounter';
import WcuArea from '../components/About/WcuArea';
import Testimonials from '../components/About/Testimonial';
import Team from '../components/About/Team';
import AboutBrand from '../components/About/AboutBrand';

const About = () => {
  return (
    <>
        <Breadcrumb />
        <AboutSection />
        <AboutVideo />
        <AboutCounter />
        <WcuArea />
        <Testimonials />
        <Team />
        <AboutBrand />
    </>
  )
}

export default About
