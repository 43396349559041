import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom';
import MobileMenu from "./MobileMenu";

function Header(){

    const [isMobileMenu, setMobileMenu] = useState(false);
    const MobileMenuTrueFalse = () => setMobileMenu(!isMobileMenu)

    const [sticky, setSticky] = useState("");

    // on render, set listener
    useEffect(() => {
        // console.log("hello");
        window.addEventListener("scroll", isSticky);
        return () => {
        window.removeEventListener("scroll", isSticky);
        };
    }, []);

    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 250 ? "sticky-header animated fadeInDown" : "";
        setSticky(stickyClass);
        // console.log(stickyClass);
    };

    const stickyclasses = `header__bottom ${sticky}`;

    return (
        <>
            <header className="header">
                <div className="header__top">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 p-0">
                                <ul className="header__info">
                                    <li><Link to="telto:2202588-6500"><i className="fa fa-phone-volume"></i> +2 (202) 588-6500</Link>
                                    </li>
                                    <li><Link to="mailto:info@pixento24.com"><i className="fal fa-envelope-open"></i>
                                            info@pixento24.com</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-md-12 my-auto p-0">
                                <div className="social__links">
                                    <Link to="#0"><i className="fab fa-facebook-f"></i></Link>
                                    <Link to="#0"><i className="fab fa-twitter"></i></Link>
                                    <Link to="#0"><i className="fab fa-pinterest-p"></i></Link>
                                    <Link to="#0"><i className="fab fa-linkedin-in"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={stickyclasses}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="navarea">
                                    <Link to="/" className="site-logo">
                                        <img src="assets/images/logo/logo.png" alt="LOGO" />
                                    </Link>
                                    <div className="mainmenu d-none d-lg-block">
                                        <nav id="mobile-menu">
                                            <ul>
                                                <li className="menu_has_children">
                                                    <Link to="/">Home</Link>
                                                </li>
                                                <li><Link to="/about">About</Link></li>
                                                
                                                <li><Link to="/our-products">Our Products</Link></li>
                                                <li><Link to="project.html">Project</Link></li>
                                                <li><Link to="news.html">Article</Link>
                                                    {/* <ul className="sub-menu">
                                                        <li><Link to="news-details.html">Article Details</Link></li>
                                                    </ul> */}
                                                </li>
                                                <li><Link to="/contact">Contact</Link></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="mobile-menu mean-container d-block d-lg-none ">
                                        <div className="mean-bar">
                                            <a onClick={MobileMenuTrueFalse} href="#nav" className={isMobileMenu ? "meanmenu-reveal d-none" : "meanmenu-reveal"} >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </a>
                                            <a onClick={MobileMenuTrueFalse} href="#nav" className={isMobileMenu ? "meanmenu-reveal" : "meanmenu-reveal d-none"} style={{ "right": "0px", "left": "auto", "textAlign": "center", "textIndent": "0px", "fontSize": "18px" }}>
                                                X
                                            </a>
                                        </div>
                                        {isMobileMenu &&
                                            <MobileMenu />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-2 my-auto">
                                <div className="lang-quote">
                                    {/* <div className="language">
                                        <i className="far fa-globe"></i>
                                        <select>
                                            <option>Eng</option>
                                            <option>Rus</option>
                                            <option>Bng</option>
                                            <option>Hp</option>
                                            <option>Frn</option>
                                        </select>
                                    </div> */}
                                    <Link to="contact.html" className="quote-btn">Get a quote</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;