import React from 'react'

const AboutBrand = () => {
  return (
    <>
        <div className="brand-section pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="brand-carousel owl-carousel">
                            <div className="brand-carousel__item">
                                <img className="default" src="assets/images/brand/brand-1.png" alt="" />
                                <img className="hover" src="assets/images/brand/brand-1-h.png" alt="" />
                            </div>
                            <div className="brand-carousel__item">
                                <img className="default" src="assets/images/brand/brand-2.png" alt="" />
                                <img className="hover" src="assets/images/brand/brand-2-h.png" alt="" />
                            </div>
                            <div className="brand-carousel__item">
                                <img className="default" src="assets/images/brand/brand-3.png" alt="" />
                                <img className="hover" src="assets/images/brand/brand-3-h.png" alt="" />
                            </div>
                            <div className="brand-carousel__item">
                                <img className="default" src="assets/images/brand/brand-4.png" alt="" />
                                <img className="hover" src="assets/images/brand/brand-4-h.png" alt="" />
                            </div>
                            <div className="brand-carousel__item">
                                <img className="default" src="assets/images/brand/brand-5.png" alt="" />
                                <img className="hover" src="assets/images/brand/brand-5-h.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AboutBrand
