import React, {useState, useEffect} from 'react';
import './ContactForm.css';

const ContactForm = () => {
    // fetch city from api for show in dropdown
    const [cityData, setCityData] = useState([]);
    const [domain, setDomain] = useState('');
    const [msg, setMsg] = useState('');

    // For Localhost
    // useEffect (() => {
    //     fetch('http://localhost/react_api/api/city')
    //     .then(response => response.json())
    //     .then(data => setCityData(data))
    //     .catch(err => console.log(err))
    // }, []);

    // For Live
    useEffect (() => {
        fetch('https://dj9.in/admin/api/city')
        .then(response => response.json())
        .then(data => setCityData(data))
        .catch(err => console.log(err))
    }, []);

    // submit Contact form data to API
    const [enteredName, setEnteredName] = useState("");
    const [enteredMobile, setEnteredMobile] = useState("");
    const [enteredEmail, setEnteredEmail] = useState("");
    const [enteredCity, setEnteredCity] = useState("");
    const [enteredMessage, setEnteredMessage] = useState("");

    const nameChangeHandler = (event) => {
        setEnteredName(event.target.value);
    }
    const mobileChangeHandler = (event) => {
        setEnteredMobile(event.target.value);
    }
    const emailChangeHandler = (event) => {
        setEnteredEmail(event.target.value);
    }
    const cityChangeHandler = (event) => {
        setEnteredCity(event.target.value);
    }
    const messageChangeHandler = (event) => {
        setEnteredMessage(event.target.value);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        setDomain('offsetprinting.in');
        const formData = {
            "fullname": enteredName,
            "mobile": enteredMobile,
            "email": enteredEmail,
            "city" : enteredCity,
            "domain" : domain,
            "message": enteredMessage
        }
        // console.log(formData);
        let liveUrl = 'https://dj9.in/admin/api/lead/reactlead';
        // let localUrl = 'http://localhost:80/react_api/api/lead/jsonlead';
        fetch(liveUrl,
            {
                method: "POST",
                body: JSON.stringify(formData)
            }
        )
        .then((response) => {return response.json() })
        .then((getres) => {
            if(getres) {
                // alert(`${getres} Thanks`);
                setMsg(getres);
                setEnteredName('');
                setEnteredMobile('');
                setEnteredEmail('');
                setEnteredCity('');
                setEnteredMessage('');
            }
        })
        .catch((error) => {
            console.log('Error: ', error);
        });
    }
    // End Here
  return (
    <>
        <section className="gta-area gta-area__2 pt-125 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="gta-bg__2">
                            <img src="assets/images/bg/gta-bg-2.png" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6 pl-50">
                        <div className="section-header mb-50">
                            <h4 className="sub-heading sub-heading__2 mb-15">Send Message
                                <span><img src="assets/images/shape/heading-shape-4.png" className="mr-10" alt="" /></span>
                            </h4>
                            <h2 className="section-title section-title__2">Get in Touch</h2>
                        </div>
                        <div className="contact-form">
                            <form onSubmit={submitHandler}>
                                <div className="form-group mt-25">
                                    <input type="hidden" name="domain" value={domain} />
                                    <input type="text" name="name" id="name" value={enteredName} onChange={nameChangeHandler} placeholder="Your Name" required />
                                </div>
                                <div className="form-group mt-25">
                                    <input type="text" name="mobile" id="mobile" placeholder="10 Digit Mobile Number" pattern="[1-9]{1}[0-9]{9}" title="Only 10 Digit Mobile Number & should not start with zero!" maxLength={10} value={enteredMobile} onChange={mobileChangeHandler} required />
                                </div>
                                <div className="form-group mt-25">
                                    <input type="email" name="email" id="mail" placeholder="Email Address" title="Valid Email Like e.g. example@xyz.com" value={enteredEmail} onChange={emailChangeHandler} required />
                                </div>
                                <div className="form-group mt-25">
                                    <select name="city" id="city" value={enteredCity} onChange={cityChangeHandler}>
                                        <option value="">Your City</option>
                                        {
                                            cityData.map((list, i) => {
                                                let str = list.city;
                                                str = str.charAt(0).toUpperCase() + str.slice(1);
                                                return (
                                                    <option key={i} value={list.id}>{str}</option>
                                                )
                                                
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group mt-25">
                                    <textarea name="message" id="message" maxLength="150" placeholder="Your Message" onChange={messageChangeHandler} value={enteredMessage}></textarea>
                                </div>
                                <button type="submit" className="site-btn site-btn__2 mt-15"><span className="icon icon__black"><i className="far fa-arrow-right"></i></span> Contact us</button>
                                <p className="ajax-response">{ msg }</p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* Contact map */}
        <div className="contact-map-area">
            <div id="contact-map"></div>
        </div>
        {/* End Here */}
    </>
  )
}

export default ContactForm
