import React from 'react';
import Breadcrumb from '../components/Contact/Breadcrumb';
import ContactInfo from '../components/Contact/ContactInfo';
import ContactForm from '../components/Contact/ContactForm';

const Contact = () => {
  return (
    <>
        <Breadcrumb />
        <ContactInfo />
        <ContactForm />
    </>
  )
}

export default Contact
